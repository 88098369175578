<!-- EnterpriseManagement.vue -->
<template>
  <div>
    <!-- 第一部分：企业详情 -->
    <div v-if="showPart1" class="part1">
      <h3>企业详情</h3>
      <Form :model="currentEnterprise" label-width="100">
        <FormItem label="名称"><Input v-model="currentEnterprise.name" /></FormItem>
        <FormItem label="大区"><Input v-model="currentEnterprise.region_name" disabled /></FormItem>
        <FormItem label="地址"><Input v-model="currentEnterprise.address" /></FormItem>
        <FormItem label="电话"><Input v-model="currentEnterprise.phone" /></FormItem>
      </Form>
      <div class="button-group">
        <Button type="primary" @click="saveEnterprise">保存</Button>
        <Button @click="goBack">返回</Button>
        <Button @click="openStaffDialog">企业员工</Button>
      </div>
    </div>
    <hr style="margin: 20px 0; border: 1px solid #ccc;" />
    <!-- 第二部分：企业属性 -->
    <div v-if="showPart2" class="part2">
      <h3>企业属性</h3>
      <Table :data="entityAttrs" :columns="attrColumns">
        <template slot-scope="{ row }" slot="action">
          <Button type="error" size="small" @click="deleteAttr(row)">删除</Button>
        </template>
      </Table>
      <div class="button-group">
        <Button type="primary" @click="addAttr">新增属性</Button>
        <Button type="primary" @click="saveAttrs">保存</Button>
      </div>
    </div>
    <hr style="margin: 20px 0; border: 1px solid #ccc;" />
    <!-- 第三部分：企业列表 -->
    <div class="part3">
      <h3>企业列表</h3>
      <Table :data="enterpriseList" :columns="enterpriseColumns">
        <template slot-scope="{ row }" slot="action">
          <Button v-if="row.isNew" type="primary" size="small" @click="saveNewEnterprise(row)">保存</Button>
          <Button v-else type="primary" size="small" @click="viewSubEnterprises(row)">查看详情</Button>
          <Button v-if="row.level !=='1'" type="error" size="small" @click="deleteEnterprise(row.id)">删除</Button>
        </template>
      </Table>
      <div class="button-group">
        <Button v-if="isShowButton(currentEnterprise)" type="primary" @click="addEnterprise">新增关联企业</Button>
        <Button v-if="isShowButton(currentEnterprise)" type="primary" @click="openAssociateDialog">新增关联关系</Button>
      </div>
    </div>

    <Modal v-model="showStaffDialog" title="企业员工" width="800">
      <EnterpriseStaff :entityId="selectedEntityId" :entityName="selectedEntityName" />
    </Modal>

    <Modal v-model="showAssociateDialog" title="新增关联企业" width="400">
      <Select v-model="selectedAssociateId" placeholder="请选择关联企业">
        <Option v-for="item in associateOptions" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
      <div slot="footer">
        <Button type="primary" @click="saveAssociate">保存</Button>
        <Button @click="showAssociateDialog = false">取消</Button>
      </div>
    </Modal>
  </div>
</template>


<script>
import { post } from '@/apis/restUtils';
import EnterpriseStaff from './EnterpriseStaff.vue';

export default {
  components: {
    EnterpriseStaff
  },
  data() {
    return {
      showPart1: false,
      showPart2: false,
      hasAttrPermission: true,
      currentEnterprise: null,
      historyEnterprise: [],
      enterpriseList: [],
      entityAttrs: [],
      attrOptions: [],
      enterpriseColumns: [
        {
          title: '名称',
          key: 'name',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.name },
                on: {
                  input: (val) => {
                    this.$set(this.enterpriseList[params.index], 'name', val);
                  }
                }
              });
            }
            return h('span', params.row.name);
          }
        },
        {
          title: '大区',
          key: 'regionId',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Select', {
                props: { value: params.row.regionId },
                on: {
                  'on-change': (value) => {
                    this.$set(this.enterpriseList[params.index], 'regionId', value);
                    this.$set(this.enterpriseList[params.index], 'region_name', this.getRegionName(value));
                  }
                }
              }, this.regionList.map(region => h('Option', {
                props: { value: region.id, label: region.name }
              })));
            }
            return h('span', params.row.region_name);
          }
        },
        {
          title: '地址',
          key: 'address',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.address },
                on: {
                  input: (val) => {
                    this.$set(this.enterpriseList[params.index], 'address', val);
                  }
                }
              });
            }
            return h('span', params.row.address);
          }
        },
        {
          title: '等级',
          key: 'level',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('span', params.row.level || '自动计算');
            }
            return h('span', params.row.level);
          }
        },
        {
          title: '电话',
          key: 'phone',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.phone },
                on: {
                  input: (val) => {
                    this.$set(this.enterpriseList[params.index], 'phone', val);
                  }
                }
              });
            }
            return h('span', params.row.phone);
          }
        },
        {
          title: '类型',
          key: 'type',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Select', {
                props: { value: params.row.type },
                on: {
                  'on-change': (value) => {
                    this.$set(this.enterpriseList[params.index], 'type', value);
                    if (value === 'subsidiary' || value === 'holding') {
                      this.$set(this.enterpriseList[params.index], 'level', parseInt(this.currentEnterprise.level) + 1);
                      this.$set(this.enterpriseList[params.index], 'parentId', this.currentEnterprise.id);
                      this.$set(this.enterpriseList[params.index], 'parent_name', this.currentEnterprise.name);
                    } else if(value === 'partner'){
                      this.$set(this.enterpriseList[params.index], 'level', null);
                      this.$set(this.enterpriseList[params.index], 'parentId', null);
                      this.$set(this.enterpriseList[params.index], 'parent_name', '');
                    } else if(value === 'group'){
                      this.$set(this.enterpriseList[params.index], 'level', 1);
                      this.$set(this.enterpriseList[params.index], 'parentId', null);
                      this.$set(this.enterpriseList[params.index], 'parent_name', '');
                    }
                  }
                }
              }, [
                h('Option', { props: { value: 'subsidiary', label: '子公司' } }),
                h('Option', { props: { value: 'holding', label: '参股公司' } }),
                h('Option', { props: { value: 'partner', label: '关联公司' } }),
                // h('Option', { props: { value: 'group', label: '集团公司' } })
              ]);
            }
            return h('span', params.row.type_name);
          }
        },
        {
          title: '上级企业',
          key: 'parent_name',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('span', params.row.parent_name || '自动设置');
            }
            return h('span', params.row.parent_name);
          }
        },
        { title: '操作', slot: 'action' }
      ],
      attrColumns: [
        {
          title: '属性',
          key: 'entityAttrId',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Select', {
                props: { value: this.entityAttrs[params.index].attrId },
                on: {
                  'on-change': (value) => {
                    this.$set(this.entityAttrs[params.index], 'attrId', value);
                  }
                }
              }, this.attrOptions.map(option => h('Option', {
                props: { value: option.id, label: option.name }
              })));
            } else {
              return h('span', params.row.attr_name);
            }
          }
        },
        {
          title: '内容',
          key: 'content',
          render: (h, params) => h('Input', {
            props: { value: this.entityAttrs[params.index].content },
            on: {
              input: (val) => {
                this.$set(this.entityAttrs[params.index], 'content', val);
              }
            }
          })
        },
        { title: '操作', slot: 'action' }
      ],
      regionList: [],
      showStaffDialog: false,
      selectedEntityId: null,
      selectedEntityName: null,
      showAssociateDialog: false, // 控制关联对话框显示
      associateOptions: [], // 关联企业下拉列表选项
      selectedAssociateId: null, // 选中的关联企业 ID
    };
  },
  mounted() {
    this.fetchRegions();
    this.fetchTopEnterprises();
  },
  methods: {
    async fetchRegions() {
      await post('/wx/wxGetRegions', {}, this.fetchRegionsConsumer);
    },
    fetchRegionsConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.regionList = response.data.entity;
      } else {
        console.error('获取大区数据失败: 未知错误');
      }
    },
    getRegionName(id) {
      for (const region of this.regionList) {
        if (region.id === id) return region.name;
      }
      return '未知';
    },
    getTypename(type) {
      switch (type) {
        case 'subsidiary':
          return '子公司';
        case 'partner':
          return '关联公司';
        case 'holding':
          return '参股公司'
        case 'group':
          return '集团公司'
        default:
          return type;
      }
    },
    getEnterpriseName(id) {
      if (this.currentEnterprise.id === id) {
        return this.currentEnterprise.name;
      } else {
        return 'unknown';
      }
    },
    fetchTopEnterprises() {
      post('/wx/enterprise/top', {}, (response) => {
        this.enterpriseList = response.data.entity.map(item => this.formatEnterprise(item));
      });
    },
    viewSubEnterprises(row) {
      if (this.currentEnterprise) {
        this.historyEnterprise.push(this.currentEnterprise);
      }
      this.showPart1 = true;
      this.currentEnterprise = row;
      if (this.hasAttrPermission) {
        this.showPart2 = true;
        this.fetchEntityAttrs(row.id);
      }
      this.fetchSubAndRelatedEnterprises(row.id);
    },
    viewBackEnterprise(row) {
      this.currentEnterprise = row;
      if (this.hasAttrPermission) {
        this.showPart2 = true;
        this.fetchEntityAttrs(row.id);
      }
      this.fetchSubAndRelatedEnterprises(row.id);
    },
    async fetchSubAndRelatedEnterprises(entityId) {
      let subRes;
      let relaRes;
      await post(`/wx/enterprise/getSub`, { 'parentId': entityId }, response => { subRes = response });
      await post(`/wx/enterprise/getRela`, { 'entity_id': entityId }, response => { relaRes = response });
      const subEnterprises = subRes.data.entity.map(item => ({ ...this.formatEnterprise(item), background: 'lightblue', isNew: false }));
      const relatedEnterprises = relaRes.data.entity.map(item => ({ ...this.formatEnterprise(item), background: 'lightyellow', isNew: false }));
      this.enterpriseList = [...subEnterprises, ...relatedEnterprises];
    },
    fetchEntityAttrs(entityId) {
      post(`/wx/enterprise/info`, { 'id': entityId }, (response) => {
        this.entityAttrs = response.data.entity.enterpriseAttrs.map(item => ({ ...item, attr_name: item.attrName, content: item.attrValue }));
        this.attrOptions = response.data.entity.attrs;
      });
    },
    formatEnterprise(item) {
      return {
        ...item,
        region_name: this.getRegionName(item.regionId),
        type_name: this.getTypename(item.type),
        parent_name: item.parentId ? this.getEnterpriseName(item.parentId) : ''
      };
    },
    goBack() {
      if (this.historyEnterprise.length !== 0) {
        this.currentEnterprise = this.historyEnterprise.pop();
        post(`/wx/enterprise/id`, { 'id': this.currentEnterprise.id }, (response) => {
          this.viewBackEnterprise(response.data.entity);
        });
      } else {
        this.fetchRegions();
        this.fetchTopEnterprises();
        this.showPart1 = false;
        this.showPart2 = false;
        this.currentEnterprise = null;
      }
    },
    openStaffDialog() {
      this.selectedEntityId = this.currentEnterprise.id;
      this.selectedEntityName = this.currentEnterprise.name;
      this.showStaffDialog = true;
    },
    addAttr() {
      const newAttr = {
        attrId: '',
        content: '',
        isNew: true
      };
      this.entityAttrs.push(newAttr);
      this.$forceUpdate();
    },
    saveAttrs() {
      const attrsToSave = this.entityAttrs.map(attr => ({
        attrId: attr.attrId,
        content: attr.content,
        entityAttrId: attr.entityAttrId,
        enterpriseId: this.currentEnterprise.id
      }));
      post('/wx/enterprises/saveAttrs', { 'attrs': attrsToSave, 'enterpriseId': this.currentEnterprise.id }, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('属性保存成功');
          this.fetchEntityAttrs(this.currentEnterprise.id);
        } else {
          this.$Message.error('属性保存失败');
        }
      });
    },
    deleteAttr(row) {
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除该企业属性？',
        onOk: () => {
          if (!row.entityAttrId) {
            this.entityAttrs = this.entityAttrs.filter(attr => attr.entityAttrId !== row.entityAttrId);
          } else {
            post('/wx/enterprise/deleteAttr', { id: row.entityAttrId }, (response) => {
              if (response && response.data && response.data.status === 'success') {
                this.$Message.success('属性删除成功');
                this.fetchEntityAttrs(this.currentEnterprise.id);
              } else {
                this.$Message.error('属性删除失败');
              }
            });
          }
        }
      });
    },
    saveEnterprise() {
      const data = {
        id: this.currentEnterprise.id,
        name: this.currentEnterprise.name,
        address: this.currentEnterprise.address,
        phone: this.currentEnterprise.phone,
      };
      post('/wx/updateEnterprise', data, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('保存成功');
        } else {
          this.$Message.error('保存失败');
        }
      });
    },
    deleteEnterprise(id) {
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除该企业吗？',
        onOk: () => {
          console.log('id ...', id);
          if(typeof id !== 'undefined' && id !== null && id !== '') {
            post('/wx/deleteEnterprise', {id}, (response) => {
              if (response && response.data && response.data.status === 'success') {
                this.$Message.success('删除成功');
                this.fetchSubAndRelatedEnterprises(this.currentEnterprise.id);
              } else {
                this.$Message.error('删除失败');
              }
            });
          } else {
            this.fetchSubAndRelatedEnterprises(this.currentEnterprise.id);
          }
        }
      });
    },
    addEnterprise() {
      const newEnterprise = {
        id: '',
        name: '',
        regionId: '',
        region_name: '',
        address: '',
        level: null,
        phone: '',
        type: '',
        parentId: null,
        parent_name: '',
        isNew: true
      };
      this.enterpriseList.push(newEnterprise);
    },
    saveNewEnterprise(row) {
      const data = {
        name: row.name,
        regionId: row.regionId,
        address: row.address,
        level: row.level,
        phone: row.phone,
        type: row.type,
        parentId: row.parentId,
        enterpriseId: this.currentEnterprise.id
      };
      console.log('saveNewEnterprise data ...', data );
      post('/wx/enterprises/addEnterprise', data, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('企业添加成功');
          this.fetchSubAndRelatedEnterprises(this.currentEnterprise.id);
        } else {
          this.$Message.error('企业添加失败');
        }
      });
    },

    openAssociateDialog() {
      // const currentType = this.currentEnterprise.type;
      const fetchUrl = '/wx/enterprise/getPartners';   //currentType === 'partner' ? '/wx/enterprise/getSubsidiaries' :
      post(fetchUrl, {}, (response) => {
        if (response && response.data && response.data.entity) {
          this.associateOptions = response.data.entity;
          this.showAssociateDialog = true;
        } else {
          this.$Message.error('获取企业列表失败');
        }
      });
    },

    saveAssociate() {
      if (!this.selectedAssociateId) {
        this.$Message.error('请选择一个企业');
        return;
      }
      const data = {
        enterpriseId: this.currentEnterprise.id,
        associateId: this.selectedAssociateId
      };
      post('/wx/enterprises/addAssociate', data, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('关联企业添加成功');
          this.showAssociateDialog = false;
          this.fetchSubAndRelatedEnterprises(this.currentEnterprise.id);
        } else {
          this.$Message.error(response.data.result);
        }
      });
    },

    isShowButton(enterprise) {
      if (enterprise && enterprise.type !== 'partner') {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
/* 标题居中 */
.part1 h3, .part2 h3, .part3 h3 {
  text-align: center;
}

/* 按钮靠右对齐 */
.part1 .button-group, .part2 .button-group, .part3 .button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* 表格背景色 */
.part3 .ivu-table-row[style*="lightblue"] { background-color: lightblue; }
.part3 .ivu-table-row[style*="lightyellow"] { background-color: lightyellow; }
</style>